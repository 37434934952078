body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background: #1e1a22 !important; */
    /* background: #09080a !important; */
    height: 100%;
}

::-webkit-scrollbar {
    width: 3px !important;
}

/* ::-webkit-scrollbar-track {
    background: black !important;
}

::-webkit-scrollbar-thumb {
    background: #00dfff !important;
}

::-webkit-scrollbar-thumb:hover {
    background: #00dfff !important;
} */

html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}
button[data-testid='close-button'] {
    outline: none !important;
    border: none !important;
}
svg[data-testid='close-icon'] {
    fill: white !important;
}
@font-face {
    font-family: 'VarinonormalRegular';
    src: local('VarinonormalRegular'), url('./assets/fonts/VarinonormalRegular-1GXaM.ttf') format('truetype');
}
@font-face {
    font-family: 'bicubik';
    src: local('bicubik'), url('./assets/fonts/bicubik/bicubik.otf') format('truetype');
}

@font-face {
    font-family: 'AmazOOSTROBBold';
    src: local('AmazOOSTROBBold'), url('./assets/fonts/AmazObitaemOstrov/AmazOOSTROBBold.ttf') format('truetype');
}

@font-face {
    font-family: 'AmazOOSTROVFine';
    src: local('AmazOOSTROVFine'), url('./assets/fonts/AmazObitaemOstrov/AmazOOSTROVFine.ttf') format('truetype');
}

@font-face {
    font-family: 'AmazOOSTROVv.2';
    src: local('AmazOOSTROVv.2'), url('./assets/fonts/AmazObitaemOstrov/AmazOOSTROVv.2.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: local('Montserrat-Regular'), url('./assets/fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: local('Montserrat-Medium'), url('./assets/fonts/Montserrat/static/Montserrat-Medium.ttf') format('truetype');
}

h2,
h3 {
    font-family: 'bicubik';
    letter-spacing: 0.1rem;
    /* font-family: roboto-regular; */
}
h4 {
    font-family: 'bicubik';
    letter-spacing: 0.1rem;
    /* font-family: roboto-regular; */
}

h1,
h4,
h5 {
    font-family: 'bicubik';
    letter-spacing: 0.05em;
}

p {
    margin-top: 0 !important;
    margin-bottom: 0rem !important;
    /* font-family: 'Montserrat-Regular', sans-serif; */
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

div,
a {
    font-weight: 400;
}

label {
    display: inline-block;
    margin-bottom: 0rem !important;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}
span {
    /* font-family: 'Montserrat-Regular', sans-serif; */
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

td {
    /* font-family: 'Montserrat-Regular', sans-serif; */
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}
th {
    font-family: 'bicubik';
    letter-spacing: 0.1em;
    /* font-size: 0.9rem !important; */
}
.customOverlay {
    background: rgba(0, 0, 0, 0.7);
}

.successModalDark,
.successModalWhite {
    width: 650px;
    overflow: hidden !important;
    /* background-color: red !important; */
    background-color: #00000045 !important;
}
.successModalWhite {
    background-color: #f0f0f0 !important;
}

@media screen and (min-width: 460px) and (max-width: 767px) {
    .successModal,
    .successModalWhite {
        width: 400px;
    }
}
@media screen and (min-width: 0px) and (max-width: 459px) {
    .successModal,
    .successModalWhite {
        width: 300px;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
iframe {
    position: relative !important;
}
.Toastify__toast--error {
    background-color: #e74c3d !important;
    color: white !important;
}
.Toastify__toast--success {
    background-color: #0abc0a !important;
    color: white !important;
}
.react-datetime-picker__calendar--open .react-calendar,
.react-datetime-picker__clock--open {
    border: 0.1rem solid #d4d4d4 !important;
    border-radius: 0.5rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 20%), 0 4px 8px rgb(0 0 0 / 6%), 0 8px 12px rgb(0 0 0 / 12%), 0 12px 16px rgb(0 0 0 / 18%);
}
.react-calendar__month-view__weekdays__weekday {
    color: #1877f2 !important;
}
.react-calendar__month-view__days__day,
.react-calendar__navigation__label__labelText {
    font-weight: bold;
}
.Toastify__toast-container {
    width: 32rem !important;
}

@media screen and (min-width: 0px) and (max-width: 500px) {
    .Toastify__toast-container {
        width: 18rem !important;
    }
    .Toastify__toast-container--top-center {
        top: 1em !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
    }
}
@-webkit-keyframes loading {
    0% {
        background-position: -150px 0;
    }
    100% {
        background-position: 200px 0;
    }
}
@keyframes loading {
    0% {
        background-position: -150px 0;
    }
    100% {
        background-position: 200px 0;
    }
}

.table {
    margin-bottom: 0rem !important;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
    color: white !important;
}

div[data-test='mdb-datatable-table'] {
    border: 1px solid #00b2cc;
    border-radius: 0.5rem;
    padding: 0;
    overflow: hidden;
}
.mdb-datatable table tbody > :last-child {
    border: none;
}

.mdb-datatable table tr {
    border-bottom: 1px solid #00b2cc;
}

.mdb-datatable table tbody > :not(:last-child) {
    border-bottom: 1px solid #00b2cc;
}

.mdb-datatable table tbody > tr td,
.mdb-datatable table thead > tr th {
    border-right: 1px solid #00b2cc;
}

table.mdb-dataTable tbody > tr:not(.selected):hover {
    background: linear-gradient(90deg, rgba(0, 223, 255, 0.25) 50.72%, rgba(0, 223, 255, 0.25) 95.59%);
    font-weight: bold !important;
    border: 0.1rem solid;
}

table.mdb-dataTable tbody > tr:hover > td > p,
table.mdb-dataTable tbody > tr:hover > td > div > p {
    color: white !important;
    font-weight: bold !important;
}

tr td {
    font-weight: bold !important;
}

@media screen and (min-width: 993px) and (max-width: 9999px) {
    table.mdb-dataTable thead,
    table thead.mdb-dataTable-head {
        background: linear-gradient(90deg, #00b2cc 5.72%, #00b1cc9a 95.59%);
        color: white !important;
    }
}
@media screen and (min-width: 0px) and (max-width: 992px) {
    table.mdb-dataTable thead,
    table thead.mdb-dataTable-head {
        color: #00b2cc !important;
    }
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    table thead.mdb-dataTable-head {
        color: #00b2cc !important;
    }
}

/* react-google-charts tooltip flicker */
svg > g > g.google-visualization-tooltip {
    pointer-events: none;
}

.mdb-dataTables_paginate,
.pagination m-0 {
    display: none;
}

tr {
    border-color: #00b2cc !important;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #00b1cc !important;
}
th {
    background-color: transparent !important;
}
/* td {
    background-color: rgba(4, 6, 9, 1) !important;
} */

.table-responsive {
    overflow: hidden;
    overflow-y: hidden;
    border-radius: 0.5rem;
    border: 1px solid #00b2cc;
}

.PopupModalDark,
.PopupModalWhite {
    width: 650px;
    overflow: hidden !important;
    background-color: #000 !important;
    opacity: 1;
}
.PopupModalWhite {
    background-color: #f0f0f0 !important;
}
@media screen and (max-width: 600px) {
    .PopupModalWhite {
      width: 348px !important;
    }
  }
